import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import find from 'lodash/find';
import { cleanValue } from '../../utilities/Currency';
import { StoreModifier } from '../../utilities/storeModifiers';
import { residenceTypeLabels } from '../../values/application';

const MONTHLY_PAYMENT_CONFIRMATION_LIMIT = 3350;

const residence = {
  address: {
    city: null,
    state: null,
    street: null,
    street2: null,
    zipCode: null
  },
  monthlyPayment: 0,
  monthlyPaymentConfirmed: false,
  monthlyPaymentConfirmedValue: 0,
  numberOfYearsResided: 0,
  numberOfMonthsResided: 0,
  type: null
};

const state = {
  primary: {
    current: cloneDeep(residence),
    previous: cloneDeep(residence)
  },
  joint: {
    current: cloneDeep(residence),
    hasSameAddressAsPrimary: true,
    previous: cloneDeep(residence)
  }
};

const getters = {
  // primary applicant current residence
  primaryApplicantCurrentAddress: state => state.primary.current.address,
  primaryApplicantCurrentResidence: state => state.primary.current,
  primaryApplicantCurrentResidenceType: state => state.primary.current.type,
  primaryApplicantCurrentResidenceTypeLabel: (state, { primaryApplicantCurrentResidenceType }) =>
    get(find(residenceTypeLabels, { value: primaryApplicantCurrentResidenceType }), 'label', null),

  // primary applicant current residence monthly payment
  primaryApplicantMonthlyPaymentAtCurrentResidence:
    state => state.primary.current.monthlyPayment,
  primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber:
    state => parseInt(cleanValue(state.primary.current.monthlyPayment)),
  primaryApplicantMonthlyPaymentMatchesConfirmedValue: state =>
    state.primary.current.monthlyPayment ===
      state.primary.current.monthlyPaymentConfirmedValue,
  primaryApplicantMonthlyPaymentConfirmationRequiredAtCurrentResidence:
    (state, { primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber }) =>
      primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber >
        MONTHLY_PAYMENT_CONFIRMATION_LIMIT,
  primaryApplicantMonthlyPaymentConfirmedValueAtCurrentResidence:
    state => state.primary.current.monthlyPaymentConfirmedValue,

  // primary applicant current residence duration
  primaryApplicantNumberOfYearsAtCurrentResidence:
    state => state.primary.current.numberOfYearsResided,
  primaryApplicantNumberOfMonthsAtCurrentResidence:
    state => state.primary.current.numberOfMonthsResided,
  primaryApplicantTotalNumberOfMonthsAtCurrentResidence:
    state => parseInt((state.primary.current.numberOfYearsResided * 12) +
      state.primary.current.numberOfMonthsResided),
  primaryApplicantHasResidedAtCurrentResidenceLongerThanTwoYears:
    (state, { primaryApplicantTotalNumberOfMonthsAtCurrentResidence }) =>
      primaryApplicantTotalNumberOfMonthsAtCurrentResidence >= 24,
  primaryApplicantHasResidedAtCurrentResidenceLessThanTwoYears:
    (state, { primaryApplicantHasResidedAtCurrentResidenceLongerThanTwoYears }) =>
      !primaryApplicantHasResidedAtCurrentResidenceLongerThanTwoYears,

  // primary applicant previous residence
  primaryApplicantPreviousAddress: state => state.primary.previous.address,

  // primary applicant previous residence duration
  primaryApplicantNumberOfYearsAtPreviousResidence:
    state => state.primary.previous.numberOfYearsResided,
  primaryApplicantNumberOfMonthsAtPreviousResidence:
    state => state.primary.previous.numberOfMonthsResided,
  primaryApplicantTotalNumberOfMonthsAtPreviousResidence:
    state => parseInt((state.primary.previous.numberOfYearsResided * 12) +
      state.primary.previous.numberOfMonthsResided),
  primaryApplicantHasResidedAtPreviousResidenceLongerThanTwoYears:
    (state, { primaryApplicantTotalNumberOfMonthsAtPreviousResidence }) =>
      primaryApplicantTotalNumberOfMonthsAtPreviousResidence >= 24,
  primaryApplicantHasResidedAtPreviousResidenceLessThanTwoYears:
    (state, { primaryApplicantHasResidedAtPreviousResidenceLongerThanTwoYears }) =>
      !primaryApplicantHasResidedAtPreviousResidenceLongerThanTwoYears,

  // joint applicant current residence
  jointApplicantCurrentAddress:
    (state, { jointApplicantHasSameAddressAsPrimary }, rootState, rootGetters) =>
      jointApplicantHasSameAddressAsPrimary
        ? rootGetters['residence/primaryApplicantCurrentAddress']
        : state.joint.current.address,
  jointApplicantCurrentResidence: state => state.joint.current,
  jointApplicantCurrentResidenceType: state => state.joint.current.type,
  jointApplicantHasSameAddressAsPrimary:
    state => state.joint.hasSameAddressAsPrimary,
  jointApplicantHasDifferentAddressAsPrimary:
    state => !state.joint.hasSameAddressAsPrimary,

  // joint applicant current residence monthly payment
  jointApplicantMonthlyPaymentAtCurrentResidence:
    state => state.joint.current.monthlyPayment,
  jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber:
    state => parseInt(cleanValue(state.joint.current.monthlyPayment)),
  jointApplicantMonthlyPaymentMatchesConfirmedValue:
    state => state.joint.current.monthlyPayment ===
      state.joint.current.monthlyPaymentConfirmedValue,
  jointApplicantMonthlyPaymentConfirmationRequiredAtCurrentResidence:
    (state, { jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber }) =>
      jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber >
        MONTHLY_PAYMENT_CONFIRMATION_LIMIT,
  jointApplicantMonthlyPaymentConfirmedValueAtCurrentResidence:
    state => state.joint.current.monthlyPaymentConfirmedValue,

  // joint applicant current residence duration
  jointApplicantNumberOfYearsAtCurrentResidence:
    state => state.joint.current.numberOfYearsResided,
  jointApplicantNumberOfMonthsAtCurrentResidence:
    state => state.joint.current.numberOfMonthsResided,
  jointApplicantTotalNumberOfMonthsAtCurrentResidence:
    state => parseInt((state.joint.current.numberOfYearsResided * 12) +
      state.joint.current.numberOfMonthsResided),
  jointApplicantHasResidedAtCurrentResidenceLongerThanTwoYears:
    (state, { jointApplicantTotalNumberOfMonthsAtCurrentResidence }) =>
      jointApplicantTotalNumberOfMonthsAtCurrentResidence >= 24,
  jointApplicantHasResidedAtCurrentResidenceLessThanTwoYears:
    (state, { jointApplicantHasResidedAtCurrentResidenceLongerThanTwoYears }) =>
      !jointApplicantHasResidedAtCurrentResidenceLongerThanTwoYears,

  // joint applicant previous residence
  jointApplicantPreviousAddress: state => state.joint.previous.address
};

const actions = {
  setPrimaryApplicantCurrentAddress ({ commit }, currentAddress) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_CITY', get(currentAddress, 'city', null));
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STATE', get(currentAddress, 'state', null));
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET', get(currentAddress, 'street', null));
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET_TWO', get(currentAddress, 'street2', null));
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_ZIP_CODE', get(currentAddress, 'zipCode', null));
  },
  setPrimaryApplicantCurrentAddressCity ({ commit }, city) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_CITY', city);
  },
  setPrimaryApplicantCurrentAddressState ({ commit }, addressState) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STATE', addressState);
  },
  setPrimaryApplicantCurrentAddressStreet ({ commit }, street) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET', street);
  },
  setPrimaryApplicantCurrentAddressStreetTwo ({ commit }, street2) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET_TWO', street2);
  },
  setPrimaryApplicantCurrentAddressZipCode ({ commit }, zipCode) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_ZIP_CODE', zipCode);
  },
  setPrimaryApplicantCurrentResidenceType ({ commit }, type) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_RESIDENCE_TYPE', type);
  },
  setPrimaryApplicantNumberOfYearsAtCurrentResidence ({ commit }, numberOfYearsResided) {
    commit('SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_ADDRESS', numberOfYearsResided);
  },
  setPrimaryApplicantNumberOfMonthsAtCurrentResidence ({ commit }, numberOfMonthsResided) {
    commit('SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_ADDRESS', numberOfMonthsResided);
  },
  setPrimaryApplicantMonthlyPaymentAtCurrentResidence ({ commit }, monthlyPayment) {
    commit('SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_AT_CURRENT_ADDRESS', monthlyPayment);
  },
  setPrimaryApplicantMonthlyPaymentConfirmedAtCurrentResidence ({ commit }, monthlyPaymentConfirmed) {
    commit('SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_AT_CURRENT_ADDRESS', monthlyPaymentConfirmed);
  },
  setPrimaryApplicantMonthlyPaymentConfirmedValueAtCurrentResidence ({ commit }, monthlyPaymentConfirmedValue) {
    commit('SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_VALUE_AT_CURRENT_ADDRESS', monthlyPaymentConfirmedValue);
  },

  setPrimaryApplicantPreviousAddress ({ commit }, previousAddress) {
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_CITY', get(previousAddress, 'city', null));
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STATE', get(previousAddress, 'state', null));
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STREET', get(previousAddress, 'street', null));
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STREET_TWO', get(previousAddress, 'street2', null));
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_ZIP_CODE', get(previousAddress, 'zipCode', null));
  },
  setPrimaryApplicantPreviousResidenceType ({ commit }, type) {
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_RESIDENCE_TYPE', type);
  },
  setPrimaryApplicantNumberOfYearsAtPreviousResidence ({ commit }, numberOfYearsResided) {
    commit('SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_ADDRESS', numberOfYearsResided);
  },
  setPrimaryApplicantNumberOfMonthsAtPreviousResidence ({ commit }, numberOfMonthsResided) {
    commit('SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_ADDRESS', numberOfMonthsResided);
  },

  // joint applicant, current residence
  setJointApplicantCurrentAddress ({ commit }, currentAddress) {
    commit('SET_JOINT_APPLICANT_CURRENT_ADDRESS_CITY', get(currentAddress, 'city', null));
    commit('SET_JOINT_APPLICANT_CURRENT_ADDRESS_STATE', get(currentAddress, 'state', null));
    commit('SET_JOINT_APPLICANT_CURRENT_ADDRESS_STREET', get(currentAddress, 'street', null));
    commit('SET_JOINT_APPLICANT_CURRENT_ADDRESS_STREET_TWO', get(currentAddress, 'street2', null));
    commit('SET_JOINT_APPLICANT_CURRENT_ADDRESS_ZIP_CODE', get(currentAddress, 'zipCode', null));
  },
  setJointApplicantNumberOfYearsAtCurrentResidence ({ commit }, numberOfYearsResided) {
    commit('SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_ADDRESS', numberOfYearsResided);
  },
  setJointApplicantNumberOfMonthsAtCurrentResidence ({ commit }, numberOfMonthsResided) {
    commit('SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_ADDRESS', numberOfMonthsResided);
  },
  setJointApplicantMonthlyPaymentAtCurrentResidence ({ commit }, monthlyPayment) {
    commit('SET_JOINT_APPLICANT_MONTHLY_PAYMENT_AT_CURRENT_ADDRESS', monthlyPayment);
  },
  setJointApplicantMonthlyPaymentConfirmedAtCurrentResidence ({ commit }, monthlyPaymentConfirmed) {
    commit('SET_JOINT_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_AT_CURRENT_ADDRESS', monthlyPaymentConfirmed);
  },
  setJointApplicantMonthlyPaymentConfirmedValueAtCurrentResidence ({ commit }, monthlyPaymentConfirmedValue) {
    commit('SET_JOINT_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_VALUE_AT_CURRENT_ADDRESS', monthlyPaymentConfirmedValue);
  },
  setJointApplicantHasSameAddressAsPrimary ({ commit }, hasSameAddressAsPrimary) {
    commit('SET_JOINT_APPLICANT_HAS_SAME_ADDRESS_AS_PRIMARY', hasSameAddressAsPrimary);
  },

  // joint applicant, previous residence
  setJointApplicantPreviousAddress ({ commit }, previousAddress) {
    commit('SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_CITY', get(previousAddress, 'city', null));
    commit('SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STATE', get(previousAddress, 'state', null));
    commit('SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STREET', get(previousAddress, 'street', null));
    commit('SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STREET_TWO', get(previousAddress, 'street2', null));
    commit('SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_ZIP_CODE', get(previousAddress, 'zipCode', null));
  }
};

const mutations = {
  SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_CITY: (state, city) => {
    state.primary.current.address.city = city;
  },
  SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STATE: (state, addressState) => {
    state.primary.current.address.state = addressState;
  },
  SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET: (state, street) => {
    state.primary.current.address.street = street;
  },
  SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET_TWO: (state, street2) => {
    state.primary.current.address.street2 = street2;
  },
  SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_ZIP_CODE: (state, zipCode) => {
    state.primary.current.address.zipCode = zipCode;
  },
  SET_PRIMARY_APPLICANT_CURRENT_RESIDENCE_TYPE: (state, type) => {
    state.primary.current.type = type;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_ADDRESS: (state, numberOfYearsResided) => {
    state.primary.current.numberOfYearsResided = numberOfYearsResided;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_ADDRESS: (state, numberOfMonthsResided) => {
    state.primary.current.numberOfMonthsResided = numberOfMonthsResided;
  },
  SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_AT_CURRENT_ADDRESS: (state, monthlyPayment) => {
    state.primary.current.monthlyPayment = monthlyPayment;
  },
  SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_AT_CURRENT_ADDRESS: (state, monthlyPaymentConfirmed) => {
    state.primary.current.monthlyPaymentConfirmed = monthlyPaymentConfirmed;
  },
  SET_PRIMARY_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_VALUE_AT_CURRENT_ADDRESS: (state, monthlyPaymentConfirmedValue) => {
    state.primary.current.monthlyPaymentConfirmedValue = monthlyPaymentConfirmedValue;
  },

  SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_CITY: (state, city) => {
    state.primary.previous.address.city = city;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STATE: (state, addressState) => {
    state.primary.previous.address.state = addressState;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STREET: (state, street) => {
    state.primary.previous.address.street = street;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_STREET_TWO: (state, street2) => {
    state.primary.previous.address.street2 = street2;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_ADDRESS_ZIP_CODE: (state, zipCode) => {
    state.primary.previous.address.zipCode = zipCode;
  },
  SET_PRIMARY_APPLICANT_PREVIOUS_RESIDENCE_TYPE: (state, type) => {
    state.primary.previous.type = type;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_ADDRESS: (state, numberOfYearsResided) => {
    state.primary.previous.numberOfYearsResided = numberOfYearsResided;
  },
  SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_ADDRESS: (state, numberOfMonthsResided) => {
    state.primary.previous.numberOfMonthsResided = numberOfMonthsResided;
  },

  SET_JOINT_APPLICANT_CURRENT_ADDRESS_CITY: (state, city) => {
    state.joint.current.address.city = city;
  },
  SET_JOINT_APPLICANT_CURRENT_ADDRESS_STATE: (state, addressState) => {
    state.joint.current.address.state = addressState;
  },
  SET_JOINT_APPLICANT_CURRENT_ADDRESS_STREET: (state, street) => {
    state.joint.current.address.street = street;
  },
  SET_JOINT_APPLICANT_CURRENT_ADDRESS_STREET_TWO: (state, street2) => {
    state.joint.current.address.street2 = street2;
  },
  SET_JOINT_APPLICANT_CURRENT_ADDRESS_ZIP_CODE: (state, zipCode) => {
    state.joint.current.address.zipCode = zipCode;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_ADDRESS: (state, numberOfYearsResided) => {
    state.joint.current.numberOfYearsResided = numberOfYearsResided;
  },
  SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_ADDRESS: (state, numberOfMonthsResided) => {
    state.joint.current.numberOfMonthsResided = numberOfMonthsResided;
  },
  SET_JOINT_APPLICANT_MONTHLY_PAYMENT_AT_CURRENT_ADDRESS: (state, monthlyPayment) => {
    state.joint.current.monthlyPayment = monthlyPayment;
  },
  SET_JOINT_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_AT_CURRENT_ADDRESS: (state, monthlyPaymentConfirmed) => {
    state.joint.current.monthlyPaymentConfirmed = monthlyPaymentConfirmed;
  },
  SET_JOINT_APPLICANT_MONTHLY_PAYMENT_CONFIRMED_VALUE_AT_CURRENT_ADDRESS: (state, monthlyPaymentConfirmedValue) => {
    state.joint.current.monthlyPaymentConfirmedValue = monthlyPaymentConfirmedValue;
  },
  SET_JOINT_APPLICANT_HAS_SAME_ADDRESS_AS_PRIMARY: (state, hasSameAddressAsPrimary) => {
    state.joint.hasSameAddressAsPrimary = hasSameAddressAsPrimary;
  },

  SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_CITY: (state, city) => {
    state.joint.previous.address.city = city;
  },
  SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STATE: (state, addressState) => {
    state.joint.previous.address.state = addressState;
  },
  SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STREET: (state, street) => {
    state.joint.previous.address.street = street;
  },
  SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_STREET_TWO: (state, street2) => {
    state.joint.previous.address.street2 = street2;
  },
  SET_JOINT_APPLICANT_PREVIOUS_ADDRESS_ZIP_CODE: (state, zipCode) => {
    state.joint.previous.address.zipCode = zipCode;
  }
};

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
